<script>
import Layout from '../../layouts/main';
import appConfig from '@/app.config';
import { keyMaster, masterComputed, masterMethods, clientMethods, checkPermission, checkPermissionName } from '@/state/helpers';
import { maxLength, required, url } from 'vuelidate/lib/validators';
import { regexNumber } from '@/utils/format';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import { getAddressByZip, getPostCodeByAddress } from 'postcode-address-get';
import Company from '@/components/Company/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import { showMessage } from '@/utils/messages';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: 'エンドクライアント新規登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Company,
        Layout,
        KeyWordTag,
        Footer
    },
    data() {
        return {
            index: 1,
            loading: false,
            id: this.$route.query.id,
            form: {
                corp_number: '',
                name: '',
                kana_name: '',
                phone_number: '',
                profile: '',
                address_1: '',
                address_2: '',
                url: '',
                postal_code: '',
                province: ''
            },
            listDataMasterPartner: [],
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            configKeyword: {
                error: false,
                arrayTag: []
            },
            arrRoleClient: ['client.register', 'client.edit']
        };
    },
    computed: {
        ...masterComputed
    },
    validations: {
        form: {
            name: { required, maxLength: maxLength(255) },
            phone_number: { maxLength: maxLength(20) },
            kana_name: {
                required,
                maxLength: maxLength(255),
                katakana() {
                    if (this.form.kana_name && this.form.kana_name.length < 255) {
                        const katakanaRegex = /^[\u30A0-\u30FF\s]+$/u;

                        if (katakanaRegex.test(this.form.kana_name)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }
            },
            postal_code: { required, maxLength: maxLength(20) },
            province: { required, maxLength: maxLength(255) },
            address_1: { required, maxLength: maxLength(255) },
            address_2: { maxLength: maxLength(255) },
            url: { url }
        }
    },
    beforeRouteUpdate(to, from, next) {
        // Xác định nếu có tham số id trong đường dẫn mới
        const queryId = to.query.id;
        if (!queryId) {
            this.resetForm();
        }
        // Gọi next để tiếp tục chuyển route
        next();
    },
    mounted() {
        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id);
        }
        this.getListMasterPartner();
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...clientMethods,
        resetForm() {
            this.form = {
                name: '',
                kana_name: '',
                phone_number: '',
                profile: '',
                address_1: '',
                address_2: '',
                url: '',
                postal_code: '',
                province: ''
            };
            this.configKeyword.arrayTag = [];
            this.configKeyword.error = false;
        },
        checkPermiss(listRole) {
            return checkPermissionName(listRole);
        },
        getDetail(id) {
            this.loading = true;
            this.detailClient(id).then((data) => {
                this.form = data;
                this.loading = false;
                this.configKeyword.arrayTag = data.keywords;
            });
        },
        getListMasterPartner() {
            this.listMaster(keyMaster['agency.type']).then((data) => {
                this.listDataMasterPartner = data;
            });
        },
        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid || this.configKeyword.error) {
                return;
            } else {
                this.register();
            }
        },
        register() {
            if (this.form.name) {
                this.loading = true;
                let dataObject = Object.assign(this.form);
                dataObject.keywords = this.configKeyword.arrayTag;
                if (this.$route.query.id) {
                    this.updateClient(dataObject).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, 'エンドクライアントが更新されました。');
                            this.getDetail(this.$route.query.id);
                        } else {
                            if (data.message === 'name.unique') {
                                showMessage(data.code, this.$bvToast, this.$t('client.name_unique'));
                            } else {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }
                            this.loading = false;
                        }
                    });
                } else {
                    this.loading = true;
                    this.registerClient(dataObject).then((data) => {
                        if (data.code == 200) {
                            this.$router
                                .push({
                                    path: '/client/form/view',
                                    query: {
                                        id: data.data.id
                                    }
                                })
                                .then(() => {
                                    showMessage(data.code, this.$bvToast, 'エンドクライアントが登録されました');
                                });
                        } else {
                            if (data.message === 'name.unique') {
                                showMessage(data.code, this.$bvToast, this.$t('client.name_unique'));
                            } else {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }
                            this.loading = false;
                        }
                    });
                }
            }
        },
        goToEdit(path, id) {
            this.$router.push({ path: path, query: { id: id } });
        },
        inputCode() {
            this.form.postal_code = regexNumber(this.form.postal_code);
            if (!this.form.postal_code) {
                this.form.province = this.form.address_1 = '';
            } else {
                if (this.form.postal_code.length < 3) return;
                getAddressByZip(this.form.postal_code, (address) => {
                    if (address.status == 200) {
                        this.form.province = address.prefecture;
                        this.form.address_1 = address.city + address.area;
                    }
                });
            }
        },
        inputAddress() {
            getPostCodeByAddress(this.form.province + this.form.address_1, (dataPostcode) => {
                if (dataPostcode.status == 200) this.form.postal_code = dataPostcode.postcode;
            });
        },
        inputPhone() {
            this.form.phone_number = regexNumber(this.form.phone_number);
        },
        handleData(data) {
            if (data) {
                this.form.name = data.name;
                this.form.corp_number = data.corporate_number;
                this.form.kana_name = data.furigana;
                this.form.postal_code = data.post_code;
                this.form.address_1 = data.city ? data.city.name : '';
                this.form.address_2 = data.street_number;
                this.form.province = data.prefecture ? data.prefecture.name : '';
            }
        },
        inputCorporate() {
            let query = [`method=number`, `number=${this.form.corp_number}`];
            this.findCompany(query.join('&')).then((data) => {
                if (data.code == 200) {
                    this.handleData(data.data[0]);
                }
            });
        }
    }
};
</script>

<template>
    <Layout>
        <div v-if="!loading" class="row form__body--card form__common--center">
            <div class="col-lg-12">
                <div class="card form--sroll mb-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-8">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05"
                                            >{{ $t('client.name') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-9">
                                            <div style="position: absolute; left: 100%; top: 5px">
                                                <company @data="handleData" />
                                            </div>
                                            <input
                                                id="validationCustom05"
                                                v-model="form.name"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.name.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.name.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('client.name')
                                                    })
                                                }}</span>
                                                <span v-if="!$v.form.name.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('client.name')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05"
                                            >{{ $t('client.kana_name') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-9">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.kana_name"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.kana_name.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.kana_name.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.kana_name.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('client.kana_name')
                                                    })
                                                }}</span>
                                                <span v-if="!$v.form.kana_name.katakana && $v.form.kana_name.maxLength">
                                                    {{
                                                        $t('validateField.incorrectFormat', {
                                                            field: $t('client.kana_name')
                                                        })
                                                    }}</span
                                                >
                                                <span v-if="!$v.form.kana_name.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('client.kana_name')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">法人番号</label>
                                        <div class="col-sm-4">
                                            <input
                                                @change="inputCorporate"
                                                id="validationCustom05"
                                                v-model="form.corp_number"
                                                type="text"
                                                class="form-control"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('client.phone_number') }}</label>
                                        <div class="col-sm-9">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.phone_number"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.phone_number.$error
                                                }"
                                                @input="inputPhone"
                                            />
                                            <div v-if="submitted && $v.form.phone_number.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.phone_number.maxLength">{{
                                                    $t('validateField.max20', {
                                                        field: $t('client.phone_number')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05"
                                            >{{ $t('client.postal_code') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-9">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.postal_code"
                                                autocomplete="off"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.postal_code.$error
                                                }"
                                                @input="inputCode"
                                            />
                                            <div v-if="submitted && $v.form.postal_code.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.postal_code.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('client.postal_code')
                                                    })
                                                }}</span>
                                                <span v-if="!$v.form.postal_code.maxLength">{{
                                                    $t('validateField.max20', {
                                                        field: $t('client.postal_code')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05"
                                            >{{ $t('client.province') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-9">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.province"
                                                autocomplete="off"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.province.$error
                                                }"
                                                @input="inputAddress"
                                            />
                                            <div v-if="submitted && $v.form.province.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.province.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('client.province')
                                                    })
                                                }}</span>
                                                <span v-if="!$v.form.province.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('client.province')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05"
                                            >{{ $t('client.address_1') }}<span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-9">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.address_1"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.address_1.$error
                                                }"
                                                placeholder="市区町村"
                                                @input="inputAddress"
                                            />
                                            <div v-if="submitted && $v.form.address_1.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.address_1.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('client.address_1')
                                                    })
                                                }}</span>
                                                <span v-if="!$v.form.address_1.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('client.address_1')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('client.address_2') }}</label>
                                        <div class="col-sm-9">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.address_2"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.address_2.$error
                                                }"
                                                placeholder="番地・その他"
                                            />
                                            <div v-if="submitted && $v.form.address_2.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.address_1.maxLength">{{
                                                    $t('validateField.max255', {
                                                        field: $t('client.address_2')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('client.profile') }}</label>
                                        <div class="col-sm-9">
                                            <textarea rows="4" id="validationCustom05" v-model="form.profile" class="form-control"></textarea>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('client.url') }}</label>
                                        <div class="col-sm-9">
                                            <input
                                                id="validationCustom05"
                                                v-model="form.url"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': submitted && $v.form.url.$error
                                                }"
                                            />
                                            <div v-if="submitted && $v.form.url.$error" class="invalid-feedback">
                                                <span v-if="!$v.form.url.url">{{
                                                    $t('validateField.incorrectURL', {
                                                        field: $t('client.url')
                                                    })
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('client.keyword') }}</label>
                                        <div class="col-sm-9">
                                            <KeyWordTag :config="configKeyword" />
                                            <span v-if="configKeyword.error" class="text-danger">
                                                {{
                                                    $t('validateField.max255', {
                                                        field: $t('client.keyword')
                                                    })
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light btn-sm mr-3"
                            @click="
                                $router.push({
                                    path: '/client/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            type="submit"
                            v-if="checkPermission(arrRoleClient[0]) || (checkPermission(arrRoleClient[1]) && $route.query.id)"
                            @click="formSubmit"
                            class="btn btn-primary ml-3"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>

        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
