var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),(!_vm.loading)?_c('div',{staticClass:"row form__body--card form__common--center"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card form--sroll mb-0"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-1"}),_c('div',{staticClass:"col-sm-8"},[_c('form',{staticClass:"needs-validation",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('client.name'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('div',{staticStyle:{"position":"absolute","left":"100%","top":"5px"}},[_c('company',{on:{"data":_vm.handleData}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.name.$error
                                            },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('client.name') })))]):_vm._e(),(!_vm.$v.form.name.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('client.name') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('client.kana_name'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.kana_name),expression:"form.kana_name"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.kana_name.$error
                                            },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.form.kana_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "kana_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.kana_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.kana_name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('client.kana_name') })))]):_vm._e(),(!_vm.$v.form.kana_name.katakana && _vm.$v.form.kana_name.maxLength)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('validateField.incorrectFormat', { field: _vm.$t('client.kana_name') })))]):_vm._e(),(!_vm.$v.form.kana_name.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('client.kana_name') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3",attrs:{"for":"validationCustom05"}},[_vm._v("法人番号")]),_c('div',{staticClass:"col-sm-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.corp_number),expression:"form.corp_number"}],staticClass:"form-control",attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.form.corp_number)},on:{"change":_vm.inputCorporate,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "corp_number", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('client.phone_number')))]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone_number),expression:"form.phone_number"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.phone_number.$error
                                            },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.form.phone_number)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "phone_number", $event.target.value)},_vm.inputPhone]}}),(_vm.submitted && _vm.$v.form.phone_number.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.phone_number.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max20', { field: _vm.$t('client.phone_number') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('client.postal_code'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.postal_code),expression:"form.postal_code"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.postal_code.$error
                                            },attrs:{"id":"validationCustom05","autocomplete":"off","type":"text"},domProps:{"value":(_vm.form.postal_code)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "postal_code", $event.target.value)},_vm.inputCode]}}),(_vm.submitted && _vm.$v.form.postal_code.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.postal_code.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('client.postal_code') })))]):_vm._e(),(!_vm.$v.form.postal_code.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max20', { field: _vm.$t('client.postal_code') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('client.province'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.province),expression:"form.province"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.province.$error
                                            },attrs:{"id":"validationCustom05","autocomplete":"off","type":"text"},domProps:{"value":(_vm.form.province)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "province", $event.target.value)},_vm.inputAddress]}}),(_vm.submitted && _vm.$v.form.province.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.province.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('client.province') })))]):_vm._e(),(!_vm.$v.form.province.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('client.province') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('client.address_1'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.address_1),expression:"form.address_1"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.address_1.$error
                                            },attrs:{"id":"validationCustom05","type":"text","placeholder":"市区町村"},domProps:{"value":(_vm.form.address_1)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "address_1", $event.target.value)},_vm.inputAddress]}}),(_vm.submitted && _vm.$v.form.address_1.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.address_1.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('client.address_1') })))]):_vm._e(),(!_vm.$v.form.address_1.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('client.address_1') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('client.address_2')))]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.address_2),expression:"form.address_2"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.address_2.$error
                                            },attrs:{"id":"validationCustom05","type":"text","placeholder":"番地・その他"},domProps:{"value":(_vm.form.address_2)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "address_2", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.address_2.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.address_1.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('client.address_2') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('client.profile')))]),_c('div',{staticClass:"col-sm-9"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.profile),expression:"form.profile"}],staticClass:"form-control",attrs:{"rows":"4","id":"validationCustom05"},domProps:{"value":(_vm.form.profile)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "profile", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('client.url')))]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.url),expression:"form.url"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submitted && _vm.$v.form.url.$error
                                            },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.form.url)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "url", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.url.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.url.url)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.incorrectURL', { field: _vm.$t('client.url') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3",attrs:{"for":"validationCustom05"}},[_vm._v(_vm._s(_vm.$t('client.keyword')))]),_c('div',{staticClass:"col-sm-9"},[_c('KeyWordTag',{attrs:{"config":_vm.configKeyword}}),(_vm.configKeyword.error)?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('client.keyword') }))+" ")]):_vm._e()],1)])])])])])])]),_c('Footer',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center flex-fill"},[_c('button',{staticClass:"btn btn-light btn-sm mr-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({
                                path: '/client/list'
                            })}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),(_vm.checkPermission(_vm.arrRoleClient[0]) || (_vm.checkPermission(_vm.arrRoleClient[1]) && _vm.$route.query.id))?_c('button',{staticClass:"btn btn-primary ml-3",attrs:{"type":"submit"},on:{"click":_vm.formSubmit}},[_vm._v(" "+_vm._s(_vm.$route.query.id ? _vm.$t('btn.save') : _vm.$t('btn.register'))+" ")]):_vm._e()])])])],1):_c('div',{staticClass:"d-flex justify-content-center"},[_c('LoadingIcon')],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }